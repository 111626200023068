<template>
  <a href="#" @click.prevent="signOut"><slot>Sign Out</slot></a>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'SignOutBtn',
  methods: {
    ...mapActions(['logOut']),
    signOut(e) {
      e.preventDefault()
      this.logOut()
    },
  },
}
</script>
