<template>
  <VLayout :class="[userRoles.join(' ')]" justify-center wrap>
    <template v-if="firstName">
      <VFlex xs12 mb-2>
        <h2 class="display-2">Welcome, {{ firstName }}!</h2>
      </VFlex>
      <VFlex xs12 class="switch-account--wrap" ma-0>
        <span>Not {{ firstName }}?</span
        ><SignOutBtn class="switch-accounts-btn">Switch Accounts</SignOutBtn>
      </VFlex>
    </template>
    <VContainer>
      <VLayout justify-center>
        <VFlex sm10 md8 lg6 wrap>
          <DashboardButtonGroup
            v-if="upperRoutes.length"
            :buttons="upperRoutes"
            class="main-routes"
          />
          <DashboardButtonGroup
            v-if="lowerRoutes.length"
            :buttons="lowerRoutes"
          />
        </VFlex>
      </VLayout>
    </VContainer>
  </VLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import SignOutBtn from '@components/SignOutBtn.vue'
import DashboardButtonGroup from '@components/Dashboard/DashboardButtonGroup'

export default {
  name: 'Dashboard',
  components: { DashboardButtonGroup, SignOutBtn },

  metaInfo: {
    title: 'Dashboard',
    meta: [{ name: 'description', content: '' }],
  },
  props: {
    title: {
      type: String,
      default: 'Dashboard',
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['userRoles', 'firstName', 'currentUserRoutes']),
    upperRoutes() {
      return this.currentUserRoutes.filter((r) => r.navGroup === 'main')
    }, // upperRoutes
    lowerRoutes() {
      return this.currentUserRoutes.filter((r) => r.navGroup !== 'main')
    }, // lowerRoutes
  },
}
</script>

<style lang="scss" scoped>
.switch-account--wrap {
  margin: 10px 0;
  color: $gray-light;
}

.switch-accounts-btn {
  margin: 0 5px;
  color: $gray-light;
  cursor: pointer;
  transition: color 0.3s ease;
  &:hover,
  &:active,
  &:focus {
    color: $blue;
    text-transform: underline;
  }
}
</style>
