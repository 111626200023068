<template>
  <Layout>
    <Dashboard />
  </Layout>
</template>

<script>
import Layout from '@layouts/main'
import { mapGetters } from 'vuex'
import Dashboard from '@components/Dashboard/Dashboard'

export default {
  name: 'DashboardView',
  components: { Layout, Dashboard },
  metaInfo: {
    title: 'Dashboard',
    meta: [{ name: 'description', content: '' }],
  },
  props: {
    title: {
      type: String,
      default: 'Dashboard',
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'userRoles',
      'isProjMgr',
      'isAdmin',
      'isForeman',
      'isAccountant',
      'isAuthenticated',
      'firstName',
      'currentUserRoutes',
    ]),
  },
}
</script>

<style lang="scss" scoped>
@import '../../design/color';

.switch-account--wrap {
  margin: 10px 0;
  color: $gray-light;
}

.switch-accounts-btn {
  margin: 0 5px;
  color: $gray-light;
  cursor: pointer;
  transition: color 0.3s ease;
  &:hover,
  &:active,
  &:focus {
    color: $blue;
    text-transform: underline;
  }
}
</style>
