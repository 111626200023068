<template>
  <VCard class="pa-3 mb-4">
    <VContainer>
      <VLayout d-flex wrap sm6>
        <BaseButton
          v-for="route of buttons"
          :key="route.title"
          class="dashboard-btn"
          v-bind="route"
          large
          >{{ route.title }}</BaseButton
        >
      </VLayout>
    </VContainer>
  </VCard>
</template>

<script>
export default {
  name: 'DashboardButtonGroup',
  props: {
    buttons: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss">
.dashboard-btn.v-btn--large {
  height: 55px;
}
</style>
